/**
 * External dependencies
 */
import axios from "axios";

/**
 * Internal dependencies
 */
import route from "@/utils/route";

export default class Beacon {
    #beamIntervalMs = 30_000;
    #beaconId = null;
    #retryAfter = 0;

    constructor({ beamIntervalMs = 30_000 } = {}) {
        this.#beamIntervalMs = beamIntervalMs;

        this.restoreCachedBeacon();
    }

    restoreCachedBeacon() {
        const cache = sessionStorage.getItem("beacon");

        if (cache?.length) {
            try {
                const decoded = JSON.parse(cache);

                if (Date.now() - decoded.retryAfter > 60 * 60) {
                    // The beacon needs to be recreated
                    this.#retryAfter = 0;
                    this.#beaconId = null;

                    return;
                }

                this.#retryAfter = decoded.retryAfter;
                this.#beaconId = decoded.beaconId;
            } catch (error) {
                // skip
            }
        }
    }

    cacheBeacon() {
        try {
            sessionStorage.setItem(
                "beacon",
                JSON.stringify({
                    beaconId: this.#beaconId,
                    retryAfter: this.#retryAfter,
                }),
            );
        } catch (error) {
            // skip
        }
    }

    async create() {
        if (!this.getBeaconEnabled()) {
            return;
        }

        if (this.isThrottled()) {
            return;
        }

        try {
            if (!this.#beaconId) {
                const res = await axios.post(route("beacons.store"));
                this.#beaconId = res.data;
                this.cacheBeacon();
            }

            this.beam();
        } catch (error) {
            if (error.response.status === 429) {
                this.throttle();
            }
        }
    }

    beam() {
        const interval = setInterval(async () => {
            try {
                if (this.isThrottled()) {
                    return;
                }

                if (!this.#beaconId) {
                    this.create();

                    return;
                }

                const res = await axios.post(route("beacons.update"));

                if (res.status === 404) {
                    this.create();
                }
            } catch (error) {
                if (error.response.status === 429) {
                    this.throttle();
                } else {
                    clearInterval(interval);
                    await this.create();
                }
            }
        }, this.#beamIntervalMs);
    }

    isThrottled() {
        return (
            this.#retryAfter > Date.now() ||
            document.visibilityState === "hidden"
        );
    }

    throttle() {
        this.#retryAfter = Date.now() + this.#beamIntervalMs;
        this.cacheBeacon();
    }

    getBeaconEnabled() {
        const value = import.meta.env?.VITE_BEACON_ENABLED;
        if (!value) {
            return false;
        }

        if (typeof value === "string") {
            return ["true", "1"].includes(value.trim().toLowerCase());
        }

        return Boolean(value);
    }
}
